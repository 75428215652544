<template>
  <div class="relative min-h-screen md:flex">
    <SideNavBar />

    <div class="flex-1 p-10 text-2xl">
      <!-- header -->
      <div class="flex justify-between flex-wrap md:flex-nowwrap items-center">
        <div class="flex">
          <ChevronLeftIcon
            @click="naviageToManageAboutGallery"
            class="h-7 w-7 pt-2 cursor-pointer"
          />
          <h2 class="font-semibold">Image Gallery Upload</h2>
        </div>
      </div>
      <!-- end of header -->

      <!-- content -->
      <div v-if="isLoading" class="loader"></div>

      <div v-if="!isLoading" class="mt-5">
        <DragDropImage
          @changed="handleImage"
          :max="1"
          class="text-black"
          clearAll="Clear All"
          maxError="Maximum one file only"
        />
        <br />
        <button
          @click="uploadGalleryImage()"
          class="btn bg-green-500 w-full border-none"
        >
          Upload
        </button>
      </div>
      <!-- end of content -->
    </div>
  </div>
</template>

<script>
import SideNavBar from "../../components/SideNavBar.vue";
import DragDropImage from "../../components/DragDropImage.vue";

import { ChevronLeftIcon } from "@heroicons/vue/outline";

export default {
  name: "AddGalleryImage",
  components: { SideNavBar, DragDropImage, ChevronLeftIcon },
  data() {
    return {
      isLoading: false,
      selectedFile: [],
    };
  },
  methods: {
    handleImage(files) {
      this.selectedFile = files[0];
      console.log(files[0]);
    },
    uploadGalleryImage() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("galleryImage", this.selectedFile);

      this.axios
        .post("aboutGallery/uploadGalleryImage", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "success",
            title: response.data["message"],
          });
          this.isLoading = false;
          this.naviageToManageAboutGallery();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "Login",
              query: {
                error:
                  "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
              },
            });
            this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
          }
        });
    },
    naviageToManageAboutGallery() {
      this.$router.push({ name: "ManageGallery" });
    },
  },
};
</script>

<style>
</style>